import type { MetaFunction } from '@remix-run/node';
import { Card } from '@pulse/ui/components';
import { Outlet } from '@remix-run/react';

export const meta: MetaFunction = () => {
  return [
    { title: 'Pulse for Grange' },
    {
      name: 'description',
      content:
        'Verify policy information on a personal lines homeowners’ policy.',
    },
  ];
};

export default function LookupLayout() {
  return (
    <div className='min-h-screen w-full justify-center items-center flex'>
      <div className='container max-w-md space-y-6 py-12'>
        <div>
          <img
            src='/grange-logo.png'
            alt='Grange Insurance'
            className='w-full h-auto'
          />
        </div>
        <Card>
          <Outlet />
        </Card>
        <div className='flex gap-x-2'>
          <div>
            <p className='text-xs text-muted-foreground'>Powered by</p>
          </div>
          <div>
            <img
              src='/TL_Logo.svg'
              alt='TrustLayer logo'
              width={100}
              height={20}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
